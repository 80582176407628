import React from 'react';
import { Helmet } from 'react-helmet';

const PageNotFound = () => {
	return (
		<>
			<Helmet>
				<meta name="robots" content="noindex, nofollow" />
			</Helmet>
			<h1>404</h1>
		</>
	);
};

export default PageNotFound;
